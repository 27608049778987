import './App.css';
import moment from 'moment';

function App() {
  let titleHtml = document.querySelector('title');
  titleHtml.innerHTML = 'The percentage of Year'

  const year = moment().format('YYYY');
  const currentDate = moment();
  const startOfYear = moment().startOf('year');
  const nextYearStartDate = moment().endOf('year');
  const daysPassedThisYear = currentDate.diff(startOfYear, 'days');
  const datePercentage = Math.floor(daysPassedThisYear / nextYearStartDate.diff(startOfYear - 1, 'days') * 100);

  console.log(nextYearStartDate.diff(startOfYear - 1, 'days'));

  const percentageBarStyle = {
    width: `${datePercentage}%`
  };

  return (
    <div className="App">
      <div className='main'>
        <h1 className='percentage no-drag'>{datePercentage}%</h1>
        <div className='percentageBarBg'>
          <div className='percentageBar' style={percentageBarStyle}></div>
        </div>
        <h2 className='year no-drag'>of {year}</h2>
      </div>
      <footer>
        <span>
          email: nozaki6roku@gmail.com
          <br/>
          X: <a href='https://twitter.com/bracket_square' target='_blank' rel="noreferrer">@bracket_square</a>
        </span>
      </footer>
    </div>
  );
}

export default App;
